import { addYears, differenceInCalendarDays } from 'date-fns';
import { DateTime, Interval } from 'luxon';

export const getFormattedProfileDate = (date: Date | string) => {
  const givenDate = new Date(date);
  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  };
  const formattedDate = givenDate.toLocaleString('en-GB', options);

  return formattedDate;
};

export const calculateDays = (
  birthDate: Date,
  deathDate: Date | null,
  years: number
) => {
  const endDate = deathDate ? new Date(deathDate) : new Date();
  const startDate = new Date(birthDate);
  const lastBirthday = addYears(startDate, years);
  const differenceInDays = differenceInCalendarDays(endDate, lastBirthday);
  return differenceInDays;
};

export const calculateAgeInYearsAndDays = (
  startDate: string,
  endDate: string,
  timeZone?: string
) => {
  const offset = DateTime.fromISO(endDate, { zone: timeZone }).offset;
  const startDateTime = DateTime.fromISO(startDate)
    .setZone('UTC')
    .plus({ minutes: offset });
  const endDateTime = DateTime.fromISO(endDate)
    .setZone('UTC')
    .plus({ minutes: offset });

  const interval = Interval.fromDateTimes(startDateTime, endDateTime);
  const years = Math.floor(interval.toDuration(['years']).years);
  const intermediateDate = startDateTime.plus({ years });
  const remainingInterval = Interval.fromDateTimes(
    intermediateDate,
    endDateTime
  );
  const days = Math.floor(remainingInterval.toDuration(['days']).days);

  return { years: years, days: days };
};
