export const configuration = {
  cedApiKey: "425bf8da0fba66976cb3637f5e54b01ee66b8600abba36694691505bc767a0b3860620a110dc7d2d35aad3aa62edccfe0a17",
  environment: "production",
  version: "1.0.80",
  googleAnalytics: {

  },
  lqDataPlatform: {
    apiUrl: "https://api.longeviquest.com",
  },
};